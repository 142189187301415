<template>
     <div class="open_bid_con">
          <div class="open_bid">

               <div class="bid_con">
                    <div class="open_title">{{L['发布终止公告']}}</div>

                    <div class="outer2 flex-row" style="margin-top: 31px;">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="info2">{{L['招标主题：']}}</span>
                              </div>
                              <div class="c959">{{ bidDetail.title }}</div>
                         </div>
                    </div>

                    <div class="outer2 flex-row">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="info2">{{L['招标编号：']}}</span>
                              </div>
                              <div class="c959">{{ bidDetail.code }}</div>
                         </div>
                    </div>


                    <div class="outer2 flex-row">
                         <div class="bd3 flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">{{L['公告标题：']}}</span>
                              </div>

                              <div :class="{ validate: validate.valueTitle }">
                                   <div class="bd4_1 flex_row_start_center vBorder">
                                        <input type="text" v-model="valueTitle" maxlength="100"
                                             @input="inputEvent('valueTitle')">
                                        <span class="word3">{{ valueTitle.length }}/100</span>
                                   </div>
                                   <span class="error_msg">{{ validate.valueTitle }}</span>
                              </div>
                         </div>
                    </div>


                    <div style="margin-top: 20px;" class="flex_row_start_start outer2">
                         <div class="bd3">
                              <div class="flex_row_end_center bd31">
                                   <span class="word2">*</span>
                                   <span class="info2">{{L['公告内容：']}}</span>
                              </div>
                         </div>

                         <div>
                              <vue-ueditor-wrap v-model="content" :config="editorConfig" editor-id="editor-demo-01">
                              </vue-ueditor-wrap>

                              <div class="error_msg1">{{ validate.content }}</div>
                         </div>
                    </div>
               </div>


               <div class="box11 flex_row_center_center">

                    <div class="box12g flex-col" @click="initiate_bid">
                         <span class="txt17">{{L['确认发布']}}</span>
                    </div>
               </div>

          </div>
     </div>
</template>

<script>
import editorConfig from './editorConfig'
import { ref, reactive, getCurrentInstance, onMounted, toRefs, computed, watch } from 'vue'
import areaData from "@/assets/area.json";
import { inqUtils } from '@/utils/inquiry_utils.js'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
export default {

     setup() {
          const msg = ref('')
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const validate = reactive({
               receiverName: '',
          })
          const route = useRoute()
          const bidDetail = ref({})
          const valueTitle = ref('')
          const content = ref('')
          const router = useRouter()

          const inputEvent = (val) => {
               validate[val] = ''
          }

          const getBidDetail = () => {
               proxy.$get('v3/business/front/bid/detail', {
                    bidId: route.query.bidId
               }).then(res => {
                    if (res.state == 200) {
                         bidDetail.value = res.data
                    }
               })
          }


          const checkInputEvent = () => {
               if (!valueTitle.value) {
                    validate.valueTitle = L['请填写公告标题']
                    return false
               }


               if (!content.value) {
                    validate.content = L['请填写公告内容']
                    return false
               }

               return true
          }


          watch(content, () => {
               validate.content = ''
          })



          const initiate_bid = () => {
               if (!checkInputEvent()) {
                    return
               }

               let param = {
                    bidId: route.query.bidId,
                    content: content.value,
                    title: valueTitle.value
               }

               proxy.$post('v3/business/front/bid/publishEnd', param, 'json').then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         setTimeout(() => {
                              router.back(1)
                         }, 1500)
                    } else {
                         ElMessage.error(res.msg)
                    }
               })

          }

          onMounted(() => {
               getBidDetail()
          })

          return {
               content,
               editorConfig,
               validate,
               inputEvent,
               initiate_bid,
               bidDetail,
               valueTitle,
               L
          }
     }
}
</script>


<style lang="scss">
.validate {
     .vBorder {
          border-color: #F30606 !important;
     }

     .error_msg {
          display: block;
          margin-top: 2px;
     }
}

.error_msg {
     font-size: 12px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: #F30606;
     display: none;
}

.error_msg1 {
     font-size: 12px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: #F30606;
}

.section9 {
     .el-input__inner {
          border: none;
          height: 27px;
          width: 318px;
     }
}

#upload {
     display: none;
}

.section2g {
     .el-input__inner {
          border: none;
          height: 30px;
          width: 220px;
     }

     .el-input {
          height: 27px;
          line-height: 30px;
     }

     .el-input__icon {
          height: 27px;
          line-height: 30px;
     }
}

/*** 消除input元素 type="number" 时默认的 加减按钮*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     margin: 0;
}

/*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
input[type="number"] {
     -moz-appearance: textfield;
}

input {
     border: none;
     outline: none;
     height: 32px;
}


.open_bid_con {
     background: $colorJ;
     padding: 1px;

     .open_bid {
          width: 1200px;
          background: #FFFFFF;
          margin: 0 auto;
          margin-top: 20px;

          .bid_con {
               padding: 20px;

          }

          .open_title {
               font-size: 24px;
               font-family: Microsoft YaHei;
               font-weight: bold;
               color: #333333;
               text-align: center;
               margin-top: 21px;
          }

          .outer2 {
               width: 100%;

               .bd3 {
                    margin-top: 20px;

                    .bd31 {
                         /* margin-top: 10px; */
                         margin-right: 12px;
                         width: 113px;
                         text-align: right;

                    }

                    .word2 {
                         color: rgba(240, 2, 2, 1);
                         font-size: 14px;
                         line-height: 14px;
                         margin-right: 4px;
                    }

                    .info2 {
                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         line-height: 14px;
                         white-space: nowrap;
                    }

                    .outer15 {
                         border-radius: 4px;
                         height: 35px;
                         border: 1px dashed rgba(40, 95, 222, 1);
                         width: 150px;
                         cursor: pointer;

                         .info10 {
                              height: 14px;
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              line-height: 35px;
                              text-align: center;
                         }
                    }
               }

               .bd4_1 {
                    background: #F7F8FA;
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    padding: 0 10px;
                    border-radius: 3px;
                    width: 1030px;

                    &.w320 {
                         width: 320px !important;
                    }

                    input {
                         flex: 1;
                         background: #F7F8FA;
                    }

                    .word3 {
                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                    }
               }


               .section9 {
                    width: 320px;
                    height: 32px;
                    background: #F7F8FA;
                    border: 1px solid #C9C9C9;
                    border-radius: 3px;

                    input {
                         background: #F7F8FA;
                    }
               }


               .section2g {
                    background-color: rgba(255, 255, 255, 1);
                    border: 1px solid #C9C9C9;
                    border-radius: 3px;

                    .section21g {
                         border: 1px solid #fff;
                         height: 32px;
                    }
               }


               .set_bid_end {
                    margin-left: 20px;
               }



               .op_item {
                    margin-left: 10px;
                    width: 100px;
                    height: 36px;
                    opacity: 1;
                    border-radius: 18px;
                    text-align: center;
                    line-height: 36px;
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    color: #333333;
                    cursor: pointer;

                    &.chosen {

                         background: #6590F3;
                         color: #FFFFFF;

                    }
               }
          }

          .c959 {
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: $colorTitle1;
          }


          .box2g {
               margin-left: 23px;

               img {
                    width: 18px;
                    height: 18px;
               }

               .box3 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(201, 201, 201, 1);
               }

               .info11 {

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    margin: 0px 0 0 8px;
               }
          }

          .outer14 {
               width: 618px;
               height: 35px;
               margin: 30px 0 0 79px;

               .txt3 {
                    height: 15px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 15px;
                    margin-top: 10px;
                    display: block;
                    margin-right: 4px
               }

               .disabledUpload {
                    position: relative;

                    &:after {
                         content: '';
                         position: absolute;
                         top: 0;
                         left: 0;
                         right: 0;
                         bottom: 0;
                         opacity: 0.3;
                    }



                    .outer15 {
                         border: 1px dashed #999;
                         color: #999;

                         .info10 {
                              color: #999;
                         }
                    }
               }

               .outer15 {
                    border-radius: 4px;
                    height: 35px;
                    border: 1px dashed rgba(40, 95, 222, 1);
                    width: 150px;
                    cursor: pointer;

                    .info10 {
                         width: 70px;
                         height: 14px;
                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 10px 0 0 40px;
                    }
               }

               .word15 {
                    height: 14px;
                    color: rgba(153, 153, 153, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-top: 11px;
                    display: block;
                    margin-left: 30px;
               }
          }

          .table_bd {
               border: 1px solid #E7E7E7;
               border-bottom: none;

               .has-gutter tr th {
                    background: #F2F2F2;
               }
          }

          .box11 {
               box-shadow: 0px 2px 10px 0px rgba(131, 131, 131, 0.3);
               background-color: rgba(255, 255, 255, 1);
               height: 79px;
               margin-top: 18px;

               .box12g {
                    width: 120px;
                    height: 40px;
                    background: $colorMain;
                    border-radius: 3px;
                    cursor: pointer;
                    text-align: center;
                    line-height: 40px;

                    .txt17 {
                         color: rgba(255, 255, 255, 1);
                         font-size: 16px;
                         white-space: nowrap;
                         display: block;
                    }
               }


               .box12f {
                    width: 120px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #BFBFBF;
                    border-radius: 3px;
                    cursor: pointer;
                    text-align: center;
                    line-height: 40px;
                    margin-right: 50px;

                    .txt17 {

                         color: #333333;
                         font-size: 16px;
                         white-space: nowrap;
                         display: block;
                    }
               }
          }
     }
}
</style>